// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgJsonEditorModule } from 'ang-jsoneditor';

import { MaterialModule } from 'src/3rd-party';
import { ServicesModule } from 'src/services';
import { DialogComponentsModule } from 'src/components/dialog-components';
import { SystemRoutingService } from './system-routing.service';

import { ComponentStoreModule } from 'src/component-store';
import { HfeComponentsModule } from 'src/components/hfe-components';

import {
    SystemUpdateComponent, SystemConfigurationComponent, RawSystemConfigurationComponent,
    EnvironmentConfigurationComponent, SequenceEditorComponent,
    MigrateCollectionComponent, RavsMigrateComponent, RavsRestoreComponent,
    CsvDataLoadComponent, BoardDataLoadComponent, BoardDataPassComponent,
    DataTransformComponent, NodeEditorTransferComponent,
    RawReportQueryComponent, ReportDomainComponent, ModuleRulesComponent, TraceRulesComponent,
    DatasetPreviewComponent
} from "./components";
import { SystemBoardsService } from './system-boards.service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ServicesModule,
        DialogComponentsModule,
        NgJsonEditorModule,
        ComponentStoreModule,
        HfeComponentsModule,
    ],
    exports: [
    ],
    declarations: [
        SystemUpdateComponent,
        SystemConfigurationComponent,
        RawSystemConfigurationComponent,
        EnvironmentConfigurationComponent,
        SequenceEditorComponent,
        MigrateCollectionComponent,
        RavsMigrateComponent,
        RavsRestoreComponent,
        CsvDataLoadComponent,
        BoardDataLoadComponent,
        BoardDataPassComponent,
        DataTransformComponent,
        DatasetPreviewComponent,
        NodeEditorTransferComponent,
        RawReportQueryComponent,
        ReportDomainComponent,
        ModuleRulesComponent,
        TraceRulesComponent
    ],
    providers: [
        SystemRoutingService,
        SystemBoardsService
    ]
})
export class SystemModule {
}
