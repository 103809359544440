import { Injectable } from "@angular/core";

import {
    FilterOperationType, FilterOperationValue, UserStore,
    ZappsmithWebService, ZappsmithWebServiceApiParams, ZappsmithWebServiceParamFilter
} from "@dicorp/zappsmith-ngx-core";

import { ZappAppBoard } from "src/component-store";
import { UserPreferencesService } from "src/services";

@Injectable({
    providedIn: 'root'
})
export class HfeDatasetService {

    constructor(private userStore: UserStore,
        private zappsmithWebService: ZappsmithWebService,
        private userPreferencesService: UserPreferencesService) {
        this.userStore.activeUser._rawObject;
    }

    getDatasetConfigUsingColumnDefs(gridField: string, link_to: string, columnDefs: any[], gridType?: GridType): DatasetConfig {
        const datasetConfig: DatasetConfig = new DatasetConfig();

        switch (gridType) {
            // case GridType.LinkTo: {
            //     datasetConfig.fieldConfigurations[gridField] = {
            //         kind: "MDL_Link",
            //         link_to: link_to,
            //     };
            //     break;
            // };
            case GridType.Paged: {
                datasetConfig.fieldConfigurations[gridField] = {
                    kind: "MDL_Link",
                    link_to: link_to,
                    enable_paging: true,
                    allow_edit: true,
                    override_allow_delete: true,
                    paging_limit: 50
                };
                break;
            };
            default: {
                datasetConfig.fieldConfigurations[gridField] = {
                    kind: "MDL_List"
                };
                break;
            }
        }

        columnDefs.forEach((columnDef, index) => {
            this.processColumnDef(columnDef, index, datasetConfig)
        });

        return datasetConfig;
    }

    getZappAppBoardDatasetConfig(zappAppBoard: ZappAppBoard, gridField: string): Promise<DatasetConfig> {
        return new Promise<DatasetConfig>((resolve, reject) => {
            this.setColumnDefs(zappAppBoard).then(() => {
                const activeColumnDefs = this.getActiveColumnDefs(zappAppBoard);
                const datasetConfig = this.getDatasetConfigUsingColumnDefs(gridField, zappAppBoard.object_name, activeColumnDefs, GridType.Paged);
                resolve(datasetConfig);
            });
        });
    }

    private setColumnDefs(zappAppBoard: ZappAppBoard): Promise<void> {
        if (zappAppBoard.columnDefs) {
            return Promise.resolve()
        } else {
            return new Promise<void>((resolve, reject) => {
                const columnDefParams = new ZappsmithWebServiceApiParams('column_def');
                const columnDefFilter: ZappsmithWebServiceParamFilter = {
                    operation: FilterOperationValue.eq,
                    type: FilterOperationType.oid,
                    values: [zappAppBoard?.dataset]
                };
                columnDefParams.setParamDictValue('name', columnDefFilter);

                return this.zappsmithWebService.getApi(columnDefParams).then(
                    result => {
                        const columnDefs: any[] = result?.length > 0 && result[0].column_defs ? result[0].column_defs : [];
                        zappAppBoard.columnDefs = columnDefs;
                        resolve();
                    },
                    () => {
                        zappAppBoard.columnDefs = [];
                        resolve();
                    }
                );
            });
        }
    }

    private getActiveColumnDefs(zappAppBoard: ZappAppBoard): any[] {
        // Set which columns are hidden
        let activeColumnDefs: any[] = [];
        if (!zappAppBoard.gridState?.columnState) {
            const userColumnList = this.userPreferencesService.getUserRecordManagerColumnList(zappAppBoard?.columnDefSetting);
            activeColumnDefs = zappAppBoard.columnDefs.map(columnDef => {
                columnDef.show = userColumnList ? userColumnList?.indexOf(columnDef?.heading) >= 0 : columnDef?.active;
                return columnDef;
            })
        } else {
            const stateColumnList = zappAppBoard.gridState?.columnState.filter(columnState => {
                return !columnState.hide;
            }).map(columnState => {
                return columnState.colId;
            });
            activeColumnDefs = zappAppBoard.columnDefs.map(columnDef => {
                const field = columnDef.childField ? columnDef.field + '.' + columnDef.childField : columnDef.field;
                columnDef.show = stateColumnList?.indexOf(field) >= 0;
                return columnDef;
            })
        }

        return activeColumnDefs;
    }

    private processColumnDef(columnDef: any, columnIndex: number, datasetConfig: DatasetConfig): void {
        const field = columnDef.childField ? columnDef.field + '.' + columnDef.childField : columnDef.field;
        const fieldConfiguration = this.getColumnDefFieldConfiguration(columnDef);

        const column: any = {
            type: "GridColumn",
            name: field,
            label: columnDef.heading,
            width: columnDef.width,
            display_format: columnDef.display_format,
            hide: columnDef.show === false ? true : false
        };

        if (columnDef.menu && columnDef.menu_name) {
            // Replace '.' with '' or else HFE thinks its a path
            const menu_name = columnDef.menu_name.toString().replace('.', '');
            datasetConfig.menus[menu_name] = columnDef.menu;
            fieldConfiguration.popup = menu_name;

            column.popup_display_model = "PopUp";
            if (fieldConfiguration.kind === 'MDL_List') {
                column.representation = "CheckList";
            } else {
                column.representation = "RadioGroup";
            }
        }

        if (columnDef.use_rich_text) {
            column.use_rich_text = true;
        }

        datasetConfig.fieldConfigurations[field] = fieldConfiguration;

        datasetConfig.columns.push(column);
    }

    private getColumnDefFieldConfiguration(columnDef: any): any {
        switch (columnDef.type) {
            case 'datetime':
                return {
                    kind: 'MDL_DateTime',
                    representation: 'DateTime'
                };
        }

        let fieldConfiguration: any;
        switch (columnDef.kind) {
            case 'boolean':
                fieldConfiguration = {
                    kind: 'MDL_Boolean'
                };
                break;
            case 'int':
                fieldConfiguration = {
                    kind: 'MDL_Numeric'
                };
                break;
            case 'float':
                fieldConfiguration = {
                    kind: 'MDL_Float'
                };
                break;
            case 'number':
                fieldConfiguration = {
                    kind: 'MDL_Numeric'
                };
                break;
            case 'datetime':
                fieldConfiguration = {
                    kind: 'MDL_DateTime',
                    representation: 'DateTime'
                };
                break;
            case 'date':
                fieldConfiguration = {
                    kind: 'MDL_DateTime',
                    representation: 'Date'
                };
                break;
            case 'time':
                fieldConfiguration = {
                    kind: 'MDL_Time'
                };
                break;
            case 'set':
                switch (columnDef.type) {
                    case 'int':
                        fieldConfiguration = {
                            kind: 'MDL_Numeric'
                        };
                        break;
                    case 'float':
                        fieldConfiguration = {
                            kind: 'MDL_Float'
                        };
                        break;
                    case 'string':
                        fieldConfiguration = {
                            kind: 'MDL_Text'
                        };
                        break;
                    case 'bool':
                        fieldConfiguration = {
                            kind: 'MDL_Boolean'
                        };
                        break;
                    default:
                        fieldConfiguration = {
                            kind: 'MDL_List'
                        };
                        break;
                }
                break;
            case 'text':
                fieldConfiguration = {
                    kind: 'MDL_Text'
                };
                break;
            default:
                fieldConfiguration = {
                    kind: 'MDL_Text'
                };
                break;
        }

        if (columnDef.childField) {
            fieldConfiguration.childField = columnDef.childField;
        }

        return fieldConfiguration;
    }


}

export enum GridType {
    Local,
    // LinkTo,
    Paged
}

export class DatasetConfig {
    columns: any[] = [];
    menus: { [key: string]: any[]; } = {};
    rules: DatsetRules;

    fieldConfigurations: { [key: string]: any; } = {};

    constructor() {
        this.rules = new DatsetRules();
    }
}

export class DatsetRules {
    RUL_Eval: any[] = [];
    RUL_Speed: any[] = [];
    RUL_Skip: any[] = [];
    RUL_Check: any[] = [];

    kind: string = "RUL_Document";
    name: string = "Role_RUL";
}