<!-- Sequence Editor -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Sequence Editor</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section>
            <h5><b>NOTE:</b> Editing the a Sequence Number will affect all new records. Please verify that new defaults
                will not conflict with existing sequence numbers. Sequence Numbers should only be updated when no users
                except the Administrator are logged in.</h5>
        </section>

        <section>
            <div>
                <button mat-flat-button (click)="save()">Save</button>
                <button mat-flat-button (click)="cancel()">Cancel</button>
            </div>
        </section>

        <section>
            <!-- Revision -->
            <mat-form-field appearance="outline">
                <mat-label>{{sequenceName}}</mat-label>
                <input matInput [(ngModel)]="sequenceValue" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
        </section>

        <section>
            <h5><b>NOTE:</b> The next number used for this sequence will be the number following the number entered. For
                example, if you enter 100, the next number used will be 101.</h5>
        </section>
    </mat-card-content>
</mat-card>