import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatchFn, Route, RouterStateSnapshot, Routes, UrlSegment } from "@angular/router";

import { UserStore } from "@dicorp/zappsmith-ngx-core";

import { PortalRoutingService } from "../models";

import {
    SystemUpdateComponent, RawSystemConfigurationComponent,
    EnvironmentConfigurationComponent, SequenceEditorComponent,
    MigrateCollectionComponent, RavsMigrateComponent, RavsRestoreComponent,
    CsvDataLoadComponent, BoardDataLoadComponent, BoardDataPassComponent,
    DataTransformComponent, NodeEditorTransferComponent,
    RawReportQueryComponent, ReportDomainComponent, SystemConfigurationComponent,
    ModuleRulesComponent, TraceRulesComponent,
    DatasetPreviewComponent
} from "./components";
import { RoutingService, SessionService } from "src/services";
import { SystemBoardsService } from "./system-boards.service";
import { ZappAppBoard } from "src/component-store";
import { DocumentViewerComponent, FfeEditorComponent, RecordManagerComponent, TrackingInfoComponent } from "src/components/hfe-components";
import { EditorMode } from "@dicorp/html-ffe";

export const SYSTEM_BOARDS_PATH = 'System';

@Injectable({
    providedIn: 'root'
})
export class SystemRoutingService implements PortalRoutingService {
    constructor(private systemBoardsService: SystemBoardsService,
        private sessionService: SessionService) {
    }

    getRouterRoute(): Promise<Route> {
        return new Promise<Route>(resolve => {
            this.systemBoardsService.getSystemBoards().then(systemBoardsDict => {
                systemRoute.children.forEach(childRoute => {
                    const systemBoardKey = childRoute.data ? childRoute.data['systemBoardKey'] : undefined;
                    if (systemBoardKey && systemBoardsDict[systemBoardKey]) {
                        const zappAppBoard = systemBoardsDict[systemBoardKey];
                        childRoute.data = { zappAppBoard };
                        systemRoute.children = systemRoute.children.concat(this.createBoardRoutes(zappAppBoard));
                    }
                })

                resolve(systemRoute);
            });
        });
    }

    getToolbarRoute(): Promise<Route> {
        return new Promise<Route>(resolve => {
            this.systemBoardsService.getSystemBoards().then(systemBoardsDict => {
                systemToolbarRoute.children.forEach(childRoute => {
                    const systemBoardKey = childRoute.data ? childRoute.data['systemBoardKey'] : undefined;
                    if (systemBoardKey && systemBoardsDict[systemBoardKey]) {
                        const zappAppBoard = systemBoardsDict[systemBoardKey];
                        childRoute.data = { zappAppBoard };
                    }
                })

                resolve(systemToolbarRoute);
            });
        });
    }

    private createBoardRoutes(zappAppBoard: ZappAppBoard): Routes {
        const boardRoutes: Routes = [];

        if (zappAppBoard.permissions.canView() && !zappAppBoard.prevent_view) {
            if (!zappAppBoard.use_document_viewer) {
                boardRoutes.push({
                    component: FfeEditorComponent,
                    path: zappAppBoard.viewRecordPath.replace(SYSTEM_BOARDS_PATH + '/', ''),
                    data: {
                        zappAppBoard,
                        editorMode: EditorMode.View
                    }
                });
            } else {
                boardRoutes.push({
                    component: DocumentViewerComponent,
                    path: zappAppBoard.viewRecordPath.replace(SYSTEM_BOARDS_PATH + '/', ''),
                    data: {
                        zappAppBoard,
                        editorMode: EditorMode.View
                    }
                });
            }
        }

        if (zappAppBoard.permissions.canEdit() && !zappAppBoard.prevent_edit) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.editRecordPath.replace(SYSTEM_BOARDS_PATH + '/', ''),
                data: {
                    zappAppBoard,
                    editorMode: EditorMode.Edit
                }
            });
        }

        if (zappAppBoard.permissions.canAdd() && !zappAppBoard.prevent_add) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.addRecordPath.replace(SYSTEM_BOARDS_PATH + '/', ''),
                data: {
                    zappAppBoard: zappAppBoard.AddBoard ? zappAppBoard.AddBoard : zappAppBoard,
                    editorMode: EditorMode.Add
                }
            });
        }

        if (this.sessionService.hasPermission('RecMgrViewHistoryAbility')) {
            boardRoutes.push({
                component: TrackingInfoComponent,
                path: zappAppBoard.detailsPath.replace(SYSTEM_BOARDS_PATH + '/', ''),
                data: {
                    zappAppBoard
                }
            });
        }

        return boardRoutes;
    }
}

@Injectable({
    providedIn: 'root'
})
export class CanActivateSystemModule implements CanActivate {
    constructor(
        private userStore: UserStore,
        private routingService: RoutingService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userStore?.activeUser?.isAdmin) {
            return true;
        } else {
            return this.routingService.canActivateAnyRoute(systemRoutes, routeSnapshot, state);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
class CanActivateSystemRoute implements CanActivate {
    constructor(private sessionService: SessionService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!routeSnapshot || !routeSnapshot?.data || !routeSnapshot?.data['permission']) {
            return true;
        } else {
            const permission = routeSnapshot.data['permission'];
            return this.sessionService.hasPermission(permission);
        }
    }
}

function canMatchSystemRoute(route: Route, segments: UrlSegment[]): CanMatchFn {
    return () => {
        return inject(CanActivateSystemRoute).canActivate(route as ActivatedRouteSnapshot, null);
    }
};

const systemRoutes: Routes = [
    {
        title: 'System Update',
        path: 'system_update',
        component: SystemUpdateComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'SystemUpdateAbility' }
    },
    // {
    //     title: 'System Configuration',
    //     path: 'configuration',
    //     component: SystemConfigurationComponent,
    //     canActivate: [CanActivateSystemRoute],
    //     canMatch: [canMatchSystemRoute],
    //     data: { permission: 'SystemConfigurationAbility' }
    // },
    {
        title: 'Raw System Configuration',
        path: 'raw_configuration',
        component: RawSystemConfigurationComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'SystemConfigurationAbility' }
    },
    {
        title: 'Environment Configuration',
        path: 'environment_configuration',
        component: EnvironmentConfigurationComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'AdminAccessOnlyAbility' }
    },
    {
        title: 'Sequence Admin',
        path: 'sequence',
        data: {
            systemBoardKey: 'sequence',
            permission: 'SequenceAdminAbility'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
    },
    {
        title: 'Sequence Edit',
        path: 'sequence_edit',
        data: {
            permission: 'SequenceAdminAbility',
            hideToolbar: true
        },
        component: SequenceEditorComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute]
    },
    {
        title: 'Migrate Collection (Rpt)',
        path: 'migrate_collection',
        component: MigrateCollectionComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'MigrateCollectionAbility' }
    },
    {
        title: 'RAVS Migrate',
        path: 'ravs_migrate',
        component: RavsMigrateComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'RavsMigrateModuleAbility' }
    },
    {
        title: 'RAVS Restore',
        path: 'ravs_restore',
        component: RavsRestoreComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'RavsRestoreModuleAbility' }
    },
    {
        title: 'CSV Data Load',
        path: 'csv_data_load',
        component: CsvDataLoadComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'CsvDataLoadAbility' }
    },
    {
        title: 'Board Data Load',
        path: 'board_data_load',
        component: BoardDataLoadComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'BoardDataLoadAbility' }
    },
    {
        title: 'Board Data Pass',
        path: 'board_data_pass',
        component: BoardDataPassComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'BoardDataPassAbility' }
    },
    // {
    //     title: 'Data Transform',
    //     path: 'data_transform',
    //     component: DataTransformComponent,
    //     canActivate: [CanActivateSystemRoute],
    //     canMatch: [canMatchSystemRoute],
    //     data: { permission: 'DataTransformAbility' }
    // },
    {
        title: 'Dataset Manager',
        path: 'dataset',
        data: {
            systemBoardKey: 'dataset_store',
            permission: 'DataSetModuleAbility'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute]
    },
    {
        title: 'Dataset Preview',
        path: 'dataset_preview',
        data: {
            permission: 'DataSetModuleAbility',
            hideToolbar: true
        },
        component: DatasetPreviewComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute]
    },
    {
        title: 'Menu Manager',
        path: 'menu',
        data: {
            systemBoardKey: 'ffe_menu_store',
            permission: 'FfeMenuModuleAbility'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute]
    },
    // {
    //     title: 'FFE Setting',
    //     path: 'ffe_setting',
    //     component: FfeSettingComponent,
    // canActivate: [CanActivateSystemRoute],
    // canMatch: [canMatchSystemRoute],
    // data: { permission: 'FfeSettingModuleAbility' }
    // }
    {
        title: 'Node Editor Project',
        path: 'ne_project',
        data: {
            systemBoardKey: 'ne_project',
            permission: 'NodeEditorModuleAbility'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute]
    },
    {
        title: 'Node Editor Document',
        path: 'ne_document',
        data: {
            systemBoardKey: 'ne_document',
            permission: 'NodeEditorModuleAbility',
            hideToolbar: true
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute]
    },
    {
        title: 'Node Editor Job',
        path: 'ne_job',
        data: {
            systemBoardKey: 'ne_job',
            permission: 'NodeEditorModuleAbility',
            hideToolbar: true
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute]
    },
    {
        title: 'Node Editor Node Kind',
        path: 'ne_node_kind',
        data: {
            systemBoardKey: 'ne_node_kind',
            permission: 'NodeEditorModuleAbility'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
    },
    // {
    //     title: 'Node Editor Transfer',
    //     path: 'ne_transfer',
    //     component: NodeEditorTransferComponent,
    //     canActivate: [CanActivateSystemRoute],
    //     canMatch: [canMatchSystemRoute],
    //     data: { permission: 'NodeEditorModuleAbility' }
    // },
    {
        title: 'Raw Report Query',
        path: 'raw_report_query',
        component: RawReportQueryComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'RawReportModuleAbility' }
    },
    // {
    //     title: 'Report Domain',
    //     path: 'report_domain',
    //     component: ReportDomainComponent,
    //     canActivate: [CanActivateSystemRoute],
    //     canMatch: [canMatchSystemRoute],
    //     data: { permission: 'ReportDomainModuleAbility' }
    // },
    {
        title: 'Trace Rules',
        path: 'trace_rule',
        component: TraceRulesComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'SystemToolAbility' }
    },
    {
        title: 'Module Rules',
        path: 'module_rule',
        component: ModuleRulesComponent,
        canActivate: [CanActivateSystemRoute],
        canMatch: [canMatchSystemRoute],
        data: { permission: 'SystemToolAbility' }
    },
]

const systemRoute: Route =
{
    title: 'System',
    path: 'System',
    children: systemRoutes,
    canActivate: [CanActivateSystemModule]
};

const systemToolbarRoute: Route =
{
    title: 'System',
    path: 'System',
    children: systemRoutes.filter(route => { return !route.data || !route.data['hideToolbar'] }),
    canActivate: [CanActivateSystemModule]
};