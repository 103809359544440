import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    raw_query(context_name: string, query_name: string, parameter_dict: any, format: string = 'json'): Promise<any> {
        context_name = context_name ? context_name : "Standard";
        const params: ZappsmithBaseParmDict = {
            'context_name': 'Standard',
            'parameter_dict': parameter_dict,
            'query_name': query_name,
            'format': format
        };
        return this.zappsmithWebService.get('/report/raw_query', params);
    };

    query(context_name: string, query_name: string, parameter_dict: any, format: string = 'json'): Promise<any> {
        context_name = context_name ? context_name : "Standard";
        const filename = (format == 'json') ? 'report.json' : ((format == 'excel') ? 'report.xslx' : 'report.csv')
        const params: ZappsmithBaseParmDict = {
            'context_name': 'Standard',
            'parameter_dict': parameter_dict,
            'query_name': query_name,
            'file_name': filename,
            'format': format
        };
        if (format == 'csv') {
            return this.zappsmithWebService.get('/report/query', params);
        }
        else {
            params['responseType'] = 'arraybuffer';
            return this.zappsmithWebService.get('/report/query', params);
        }
    };

    queries(): Promise<any[]> {
        return this.zappsmithWebService.get('/report/queries', {});
    };
}