import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { JsonEditorOptions, JsonEditorComponent } from 'ang-jsoneditor';

import { AlertService, AlertType, GeneralDatasetService } from 'src/services';
import { ZappAppBoard } from 'src/component-store';

@Component({
    selector: 'zs-document-viewer',
    templateUrl: 'document-viewer.component.html',
    styleUrls: ['document-viewer.component.scss']
})
export class DocumentViewerComponent {
    @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
    public editorOptions: JsonEditorOptions;

    public data: any = {};

    doc_id: string;
    version: string;

    private zappAppBoard: ZappAppBoard;

    public showLoadingSpinner: boolean;

    constructor(private activatedRoute: ActivatedRoute,
        private location: Location,
        private alertService: AlertService,
        private generalDatasetService: GeneralDatasetService) {
        this.setupJsonEditorOptions();
        this.showLoadingSpinner = true;
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(data => {
            if (data['zappAppBoard']) {
                this.zappAppBoard = data['zappAppBoard'];
            }

            this.initialize();
        });

        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.doc_id = queryParams['doc_id'];
            if (queryParams['version']) {
                this.version = queryParams['version'];
            }

            this.initialize();
        });
    }

    private setupJsonEditorOptions(): void {
        this.editorOptions = new JsonEditorOptions();

        this.editorOptions.mode = 'view';
        this.editorOptions.modes = ['view'];
        this.editorOptions.indentation = 4;
        this.editorOptions.name = 'NeDocument';

        this.editorOptions.mainMenuBar = true;
        // this.editorOptions.navigationBar = true;
    }

    private initialize(): void {
        if (this.zappAppBoard && this.doc_id) {
            this.reload();
        }
    }

    private reload(): void {
        this.generalDatasetService.get(this.zappAppBoard?.ffeEditorOptions.baseObject, this.doc_id, this.version, undefined).then(
            result => {
                this.data = result;
                this.showLoadingSpinner = false;
            },
            result => {
                this.showLoadingSpinner = false;
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not get record",
                    type: AlertType.error
                });
            });
    }

    close(): void {
        this.location.back();
    }
}
