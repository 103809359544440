import { Inject, Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { Subject } from "rxjs";

import { AppAuthService } from "@dicorp/zappsmith-ngx-auth";
import { APP_ENVIRONMENT_OPTIONS, AppEnvironmentOptions, CommonFunctions } from "@dicorp/zappsmith-ngx-core";

import { BoardActionRule, BoardAdditionalActions, ZappAppBoard, ZappAppBoardStore } from "src/component-store";
import { AlertService, AlertType, GeneralDatasetService } from "src/services";
import { SYSTEM_BOARDS_PATH } from "./system-routing.service";

interface SystemBoardsDict { [key: string]: ZappAppBoard; };

@Injectable({
    providedIn: 'root'
})
export class SystemBoardsService {
    private _systemBoards: SystemBoardsDict;
    systemBoardsLoaded$ = new Subject<SystemBoardsDict>();

    constructor(private appAuthService: AppAuthService,
        private router: Router,
        private zappAppBoardStore: ZappAppBoardStore,
        private alertService: AlertService,
        private generalDatasetService: GeneralDatasetService,
        @Inject(APP_ENVIRONMENT_OPTIONS) private appEnvironmentOptions: AppEnvironmentOptions) {
        this.appAuthService.activeUser$.subscribe(activeUser => {
            if (activeUser) {
                this.createSystemBoards();
            }
        });
    }

    private createSystemBoards(): void {
        this._systemBoards = {};

        // System Boards
        this.processSystemBoardsJson(SYSTEM_BOARDS_JSON);

        const systemBoards = Object.values(this._systemBoards);
        this.zappAppBoardStore.setBoardProperties(systemBoards);
        this.zappAppBoardStore.addZappAppBoards(systemBoards);
        this.setBoardRoutes(systemBoards)

        this.systemBoardsLoaded$.next(this._systemBoards);
    }

    private processSystemBoardsJson(systemBoardsJson: any) {
        for (var systemBoardKey in systemBoardsJson) {
            const systemBoardJson = systemBoardsJson[systemBoardKey];
            this.processSystemBoardJson(systemBoardKey, systemBoardJson)
        }
    }

    private processSystemBoardJson(systemBoardKey: string, systemBoardJson: any): void {
        const systemBoard = this.createSystemBoard(systemBoardJson);

        if (systemBoard.add_board) {
            systemBoard.AddBoard = this.createSystemBoard(systemBoard.add_board);
        }

        if (systemBoardKey === 'dataset_store') {
            this.processDataSetBoard(systemBoard);
        }

        if (systemBoardKey === 'sequence') {
            this.processSequenceBoard(systemBoard);
        }

        if (systemBoardKey === 'ne_document') {
            this.processNeDocumentBoard(systemBoard);
        }

        if (systemBoardKey === 'ne_job') {
            this.processNeJobBoard(systemBoard);
        }

        if (!this._systemBoards) {
            this._systemBoards = {};
        }

        this._systemBoards[systemBoardKey] = systemBoard;
    }

    private createSystemBoard(boardJson: any): ZappAppBoard {
        const zappAppBoard = this.zappAppBoardStore.createZappAppBoard(boardJson);

        return zappAppBoard;
    }

    private setBoardRoutes(zappAppBoards: ZappAppBoard[]): void {
        for (let zappAppBoard of zappAppBoards) {
            zappAppBoard.recordManagerPath = SYSTEM_BOARDS_PATH + '/' + zappAppBoard.ApplicationLinkId;

            if (zappAppBoard.permissions.canView()) {
                zappAppBoard.viewRecordPath = zappAppBoard.recordManagerPath + '/View';
            }
            if (zappAppBoard.permissions.canEdit()) {
                zappAppBoard.editRecordPath = zappAppBoard.recordManagerPath + '/Edit';
            }
            if (zappAppBoard.permissions.canAdd()) {
                zappAppBoard.addRecordPath = zappAppBoard.recordManagerPath + '/Add';
            }

            zappAppBoard.detailsPath = zappAppBoard.recordManagerPath + '/Details';
        }
    }

    getSystemBoards(): Promise<SystemBoardsDict> {
        if (this._systemBoards) {
            return Promise.resolve(this._systemBoards);
        } else {
            return new Promise<SystemBoardsDict>(resolve => {
                const sub = this.systemBoardsLoaded$.subscribe(systemBoards => {
                    if (systemBoards) {
                        sub.unsubscribe();
                        resolve(systemBoards);
                    }
                })
            });
        }
    }

    private processDataSetBoard(zappAppBoard: ZappAppBoard): void {
        // Additional Actions
        const boardAdditionalActions: BoardAdditionalActions = {
            preview: {
                label: 'Preview',
                action: (doc_id, selectedRows) => {
                    const path = '/' + SYSTEM_BOARDS_PATH + '/dataset_preview';
                    const queryParams: Params = { dataset_id: selectedRows[0]?.ds_name };
                    this.router.navigate([path], {
                        queryParams
                    });
                    return Promise.resolve(false);
                }
            }
        }
        zappAppBoard.boardAdditionalActions = boardAdditionalActions;
    }

    private processSequenceBoard(zappAppBoard: ZappAppBoard): void {
        // Additional Actions
        const boardAdditionalActions: BoardAdditionalActions = {
            preview: {
                label: 'Edit',
                action: (doc_id, selectedRows) => {
                    const path = '/' + SYSTEM_BOARDS_PATH + '/sequence_edit';
                    const queryParams: Params = {
                        sequenceName: selectedRows[0]?.name,
                        sequenceValue: selectedRows[0]?.value
                    };
                    this.router.navigate([path], {
                        queryParams
                    });
                    return Promise.resolve(false);
                }
            }
        }
        zappAppBoard.boardAdditionalActions = boardAdditionalActions;
    }

    private processNeDocumentBoard(zappAppBoard: ZappAppBoard): void {

        const openContent = (doc_id: string, readonly?: boolean): void => {
            const origin = this.appEnvironmentOptions.baseUrl ? this.appEnvironmentOptions.baseUrl : window.location.origin;
            let path = '/node_editor/#/' + doc_id;
            if (readonly) {
                path += '?readonly=True'
            }
            window.open(origin + path, '_blank');
        }

        zappAppBoard.viewEntityOverride = (key: string) => {
            openContent(key, true);
            return Promise.resolve();
        };

        zappAppBoard.editEntityOverride = (key: string) => {
            openContent(key);
            return Promise.resolve();
        };

        zappAppBoard.doubleClickActionOverride = (entity: any) => {
            openContent(entity._id);
            return Promise.resolve();
        };

        // const boardAdditionalActions: BoardAdditionalActions = {
        //     viewContent: {
        //         label: 'View Doc',
        //         action: (doc_id) => {
        //             if (doc_id) {
        //                 openContent(doc_id, true);
        //             }
        //             return Promise.resolve(true);
        //         }
        //     },
        //     editContent: {
        //         label: 'Edit Doc',
        //         action: (doc_id) => {
        //             if (doc_id) {
        //                 openContent(doc_id);
        //             }
        //             return Promise.resolve(true);
        //         }
        //     }
        // }

        // zappAppBoard.boardAdditionalActions = boardAdditionalActions;
    }

    private processNeJobBoard(zappAppBoard: ZappAppBoard): void {
        if (!zappAppBoard.actionRules) {
            zappAppBoard.actionRules = [];
        }

        const buildBoardActionRule: BoardActionRule = {
            label: 'Build Project',
            confirmationMessage: "Are you sure you want to build the project's datasets?",
            isEmptyRowsAllowed: false,
            refreshChildren: true,
            standAlone: true,
            actionOverride: (currentKeyValues: string[], selectedRows?: any[]) => {
                if (selectedRows?.length > 0) {
                    return new Promise<any>((resolve, reject) => {
                        this.generalDatasetService.upsert('ne_job', selectedRows[0]).then(
                            result => {
                                this.alertService.addAlert({
                                    title: 'Job Submitted',
                                    message: "Job Submitted",
                                    type: AlertType.success
                                });
                            },
                            result => {
                                this.alertService.addAlert({
                                    title: 'Error',
                                    message: "Could not add build project",
                                    type: AlertType.error
                                });
                            }
                        )
                    })
                } else {
                    return Promise.reject();
                }
            }
        }

        zappAppBoard.actionRules.push(buildBoardActionRule);
    }
}

export const SYSTEM_BOARDS_JSON: any = {
    'ffe_menu_store': {
        "_id": "ffe_menu_store",
        "object_name": "ffe_menu_store",
        "static_board": true,
        "prevent_add": true,
        "prevent_edit": true,
        "prevent_delete": true,
        "use_document_viewer": true,
        "hideDetailsAction": true,
        "disableColumns": true,
        "disableDefaultActionRules": true,
        "Board": {
            "ApplicationLink": {
                "@id": "Menu",
                "@name": "Menu"
            },
            "Id": {
                "#value": "Menu"
            },
            "Name": {
                "#value": "Menu"
            },
            "Label": {
                "#value": "Menu"
            },
            "PluralLabel": {
                "#value": "Menus"
            },
            "MainPackage": {
                "#value": "Menu_PKG"
            }
        },
        "ColumnDefs": [
            {
                heading: 'Name',
                field: 'menu_name',
                kind: 'text',
                active: true
            },
            {
                heading: 'Version',
                field: '_version',
                kind: 'int',
                active: true
            }
        ]
    },
    'dataset_store': {
        "_id": "dataset_store",
        "object_name": "dataset_store",
        "static_board": true,
        "prevent_add": true,
        "prevent_edit": true,
        "prevent_delete": true,
        "use_document_viewer": true,
        "hideDetailsAction": true,
        "disableColumns": true,
        "disableDefaultActionRules": true,
        "Board": {
            "ApplicationLink": {
                "@id": "DataSet",
                "@name": "DataSet"
            },
            "Id": {
                "#value": "DataSet"
            },
            "Name": {
                "#value": "DataSet"
            },
            "Label": {
                "#value": "DataSet"
            },
            "PluralLabel": {
                "#value": "DataSets"
            },
            "MainPackage": {
                "#value": "DataSet_PKG"
            }
        },
        "ColumnDefs": [
            {
                heading: 'Name',
                field: 'ds_name',
                kind: 'text',
                active: true
            },
            {
                heading: 'Version',
                field: '_version',
                kind: 'int',
                active: true
            }
        ]
    },
    'sequence': {
        "_id": "sequence",
        "object_name": "sequence",
        "static_board": true,
        "disable_actions": true,
        "hideDetailsAction": true,
        "disableColumns": true,
        "disableDefaultActionRules": true,
        "Board": {
            "ApplicationLink": {
                "@id": "Sequence",
                "@name": "Sequence"
            },
            "Id": {
                "#value": "Sequence"
            },
            "Name": {
                "#value": "Sequence"
            },
            "Label": {
                "#value": "Sequence"
            },
            "PluralLabel": {
                "#value": "Sequences"
            },
            "MainPackage": {
                "#value": "Sequence_PKG"
            }
        },
        "ColumnDefs": [
            {
                heading: 'Sequence Name',
                field: 'name',
                kind: 'text',
                active: true
            },
            {
                heading: 'Value',
                field: 'value',
                kind: 'text',
                active: true
            }
        ]
    },
    'ne_node_kind': {
        "_id": "ne_node_kind",
        "object_name": "ne_node_kind",
        "permission_base": "NodeEditorNodeKind",
        "dataset": "NodeEditorNodeKind",
        "columnDefSetting": "NodeEditorNodeKind",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "ne_node_kind",
                "@name": "NodeEditorNodeKind"
            },
            "Id": {
                "#value": "NodeEditorNodeKind"
            },
            "Name": {
                "#value": "NodeEditorNodeKind"
            },
            "Label": {
                "#value": "NodeEditorNodeKind"
            },
            "PluralLabel": {
                "#value": "NodeEditorNodeKind"
            },
            "MainPackage": {
                "#value": "NodeEditorNodeKind_PKG"
            }
        }
    },
    'ne_project': {
        "_id": "ne_project",
        "object_name": "ne_project",
        "permission_base": "NodeEditorProject::",
        "dataset": "NodeEditorProject",
        "columnDefSetting": "NodeEditorProject",
        "static_board": true,
        "disableDefaultActionRules": true,
        "prevent_delete": true,
        "Board": {
            "ApplicationLink": {
                "@id": "ne_project",
                "@name": "NodeEditorProject"
            },
            "Id": {
                "#value": "NodeEditorProject"
            },
            "Name": {
                "#value": "NodeEditorProject"
            },
            "Label": {
                "#value": "Node Editor Project"
            },
            "PluralLabel": {
                "#value": "Node Editor Projects"
            },
            "MainPackage": {
                "#value": "NodeEditorProject_PKG"
            }
        }
    },
    'ne_document': {
        "_id": "ne_document",
        "object_name": "ne_document",
        "permission_base": "NodeEditorDocument::",
        "dataset": "NodeEditorDocumentAdd",
        "columnDefSetting": "NodeEditorDocumentAdd",
        "versionObjectOverride": "/ne_document",
        "ExternalReferenceKey": "Document.ExternalReferenceKey",
        "static_board": true,
        "disableDefaultActionRules": true,
        "Board": {
            "ApplicationLink": {
                "@id": "ne_document",
                "@name": "NodeEditorDocument"
            },
            "Id": {
                "#value": "NodeEditorDocument"
            },
            "Name": {
                "#value": "NodeEditorDocument"
            },
            "Label": {
                "#value": "Node Editor Document"
            },
            "PluralLabel": {
                "#value": "Node Editor Documents"
            },
            "MainPackage": {
                "#value": "NodeEditorDocument_PKG"
            },
            "Parents": [
                {
                    "ParentBoardLink": {
                        "#value": "ne_project",
                        "@id": "ne_project",
                        "@name": "NodeEditorProject"
                    },
                    "ParentKeyField": {
                        "#value": "project_id",
                    }
                }
            ]
        }
    },
    'ne_job': {
        "_id": "ne_job",
        "object_name": "ne_job",
        "permission_base": "NodeEditorJob::",
        "dataset": "NodeEditorJob",
        "columnDefSetting": "NodeEditorJob",
        "static_board": true,
        "disableDefaultActionRules": true,
        "Board": {
            "ApplicationLink": {
                "@id": "ne_job",
                "@name": "NodeEditorJob"
            },
            "Id": {
                "#value": "NodeEditorJob"
            },
            "Name": {
                "#value": "NodeEditorJob"
            },
            "Label": {
                "#value": "Node Editor Job"
            },
            "PluralLabel": {
                "#value": "Node Editor Jobs"
            },
            "MainPackage": {
                "#value": "NodeEditorJob_PKG"
            },
            "Parents": [
                {
                    "ParentBoardLink": {
                        "#value": "ne_project",
                        "@id": "ne_project",
                        "@name": "NodeEditorProject"
                    },
                    "ParentKeyField": {
                        "#value": "project_id",
                    }
                }
            ]
        },
        "manifest": {
            "hide_save_and_exit": true,
            "hide_save_and_add_another": true,
            "save_label": "Submit"
        }
    },
}