<!-- Loading Spinner -->
<mat-card class="raw-report-query-loading" [hidden]="!showLoadingSpinner">
    <mat-spinner mode="indeterminate" diameter="200">
    </mat-spinner>
</mat-card>

<!-- Raw Report Query -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Raw Report Query</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <section>
                <!-- Customer -->
                <mat-form-field appearance="outline">
                    <mat-label>Query Name</mat-label>
                    <mat-select [(value)]="selectedQueryName" panelWidth="">
                        <mat-option *ngFor="let queryName of queryNames" [value]="queryName">{{queryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </section>

            <section>
                <!-- Limit -->
                <mat-form-field appearance="outline">
                    <mat-label>Limit</mat-label>
                    <input matInput [(ngModel)]="queryModel.limit" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" type="number" />
                </mat-form-field>

                <!-- Branch -->
                <mat-form-field appearance="outline">
                    <mat-label>Offset</mat-label>
                    <input matInput [(ngModel)]="queryModel.offset" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" type="number" />
                </mat-form-field>
            </section>

            <!-- Raw Parameters (if populated this will override others) -->
            <section>
                <div class="json-editor-div">
                    <mat-label>Raw Parameters (if populated this will override others)</mat-label>
                    <json-editor id="rawParamsEditor" [options]="rawParamsEditorOptions" [data]="rawParamsData"
                        (change)="rawParamsDataChange()"></json-editor>
                </div>
            </section>

            <!-- Update Configuration -->
            <section>
                <div class="run-query-btn-div">
                    <button mat-flat-button [disabled]="!selectedQueryName" (click)="runQuery()">Run Query</button>
                </div>
            </section>
        </form>
    </mat-card-content>
</mat-card>

<!-- Query Results -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Query Results</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight="true">
            <mat-tab label="JSON Results">
                <div class="json-editor-div query-results-editor-div">
                    <json-editor id="queryResultsEditor" [options]="queryResultsEditorOptions" [data]="queryResultsData"
                        (change)="queryResultsDataChange()"></json-editor>
                </div>
            </mat-tab>
            <mat-tab label="Chart Results" [disabled]="!(queryResultsData?.configuration?.version === '2.0')">
                <ng-container *ngIf="queryResultsData?.configuration?.version === '2.0'">
                    <zs-hfe-portal-editor [editorOptions]="chartEditorOptions"></zs-hfe-portal-editor>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>