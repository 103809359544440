import { Component, ViewChild } from '@angular/core';
import { EditorMode, EditorOptions, IHtmlFFEApi, QueryRuleResult } from '@dicorp/html-ffe';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { AlertService, AlertType, ReportService } from 'src/services';

@Component({
    selector: 'zs-raw-report-query',
    templateUrl: 'raw-report-query.component.html',
    styleUrls: ['raw-report-query.component.scss']
})
export class RawReportQueryComponent {
    @ViewChild('rawParamsEditor', { static: false }) rawParamsEditor: JsonEditorComponent;
    public rawParamsEditorOptions: JsonEditorOptions;

    public rawParamsData: any = {};
    public updatedRawParamsData: any;

    @ViewChild('queryResultsEditor', { static: false }) queryResultsEditor: JsonEditorComponent;
    public queryResultsEditorOptions: JsonEditorOptions;

    public queryResultsData: any = {};
    public updatedQueryResultsData: any;

    queryNames: string[] = [];
    selectedQueryName: string;

    queryModel: QueryModel = {}

    showLoadingSpinner: boolean;

    public chartEditorOptions: EditorOptions;

    constructor(private alertService: AlertService, private reportService: ReportService) {
        this.getReportQueryNames();
        this.setupRawParamsEditorOptions();
        this.setupQueryResultsEditorOptions();
        this.setupHfeEditorOptions();
    }

    private getReportQueryNames(): void {
        this.reportService.queries().then(result => {
            this.queryNames = result.map(value => {
                return value.name;
            });
        });
    }

    private setupRawParamsEditorOptions(): void {
        this.rawParamsEditorOptions = new JsonEditorOptions();

        this.rawParamsEditorOptions.mode = 'code';
        this.rawParamsEditorOptions.modes = ['code', 'text', 'tree', 'view'];
        this.rawParamsEditorOptions.indentation = 4;
    }

    private setupQueryResultsEditorOptions(): void {
        this.queryResultsEditorOptions = new JsonEditorOptions();

        this.queryResultsEditorOptions.mode = 'code';
        this.queryResultsEditorOptions.modes = ['code', 'text', 'tree', 'view'];
        this.queryResultsEditorOptions.indentation = 4;
    }

    private setupHfeEditorOptions(): void {
        this.chartEditorOptions = new EditorOptions();
        this.chartEditorOptions.editorMode = EditorMode.Edit;
    }

    rawParamsDataChange(): void {
        this.updatedRawParamsData = this.rawParamsEditor.get();
    }

    queryResultsDataChange(): void {
        this.updatedQueryResultsData = this.queryResultsEditor.get();
    }

    runQuery(): void {
        this.showLoadingSpinner = true;
        let parameter_dict = {};

        this.setParmDictParts(this.queryModel, parameter_dict);
        if (this.updatedRawParamsData) {
            this.setParmDictParts(this.updatedRawParamsData, parameter_dict);
        }

        this.reportService.raw_query(null, this.selectedQueryName, parameter_dict).then(
            result => {
                this.showLoadingSpinner = false;
                this.queryResultsData = result;
                setTimeout(() => {
                    this.refreshHfeEditorOptions();
                });
            },
            result => {
                this.showLoadingSpinner = false;
                this.queryResultsData = undefined;
                this.alertService.addAlert({
                    title: 'Error: Raw Query',
                    message: result?.error,
                    type: AlertType.error
                });
            }
        );
    }

    setParmDictParts(queryModel: QueryModel, parameter_dict: any): void {
        for (var key in queryModel) {
            if (queryModel[key]) {
                parameter_dict[key] = queryModel[key];
            }
        }
        // if (queryModel?.filter) {
        //     parameter_dict['filter'] = queryModel.filter;
        // }
        // if (queryModel?.select) {
        //     parameter_dict['select'] = queryModel.select;
        // }
        // if (queryModel?.group) {
        //     parameter_dict['group'] = queryModel.group;
        // }
        // if (queryModel?.unwind) {
        //     parameter_dict['unwind'] = queryModel.unwind;
        // }
        // if (queryModel?.orderBy) {
        //     parameter_dict['order_by'] = queryModel.orderBy;
        // }
        // if (queryModel?.limit) {
        //     parameter_dict['limit'] = queryModel.limit;
        // }
        // if (queryModel?.offset) {
        //     parameter_dict['offset'] = queryModel.offset;
        // }
    }

    private refreshHfeEditorOptions(): void {
        const dataset = {
            sections: {
                "MainForm": {
                    "name": "MainForm",
                    "height": "100%",
                    "children": [
                        {
                            "type": "Chart",
                            "dm_reference": "QueryResults",
                            "query_name": "NOT_USED",
                            "height": "800px"
                        }
                    ]
                }
            },
            field_configurations: {},
            rules: {
                "kind": "RUL_Document",
                "name": "Role_RUL",
                "RUL_Skip": []
            },
            menus: {}
        };

        this.chartEditorOptions?.html_ffe_api?.load_dataset(dataset);
        this.chartEditorOptions?.html_ffe_api?.reload_record({
            "QueryResults": { '#value': this.queryResultsData }
        });
    }
}

interface QueryModel {
    filter?: any;
    select?: any;
    group?: any;
    unwind?: any;
    orderBy?: any;

    limit?: number;
    offset?: number;
}
