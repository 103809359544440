<ng-container *ngIf="menuBoard">
    <zs-ffe-editor [zappAppBoardInput]="menuBoard"></zs-ffe-editor>
</ng-container>

<ng-container *ngFor="let messageDocumentsUri of configurationService?.model?.messageDocumentsUris">
    <div [innerHTML]="messageDocumentsUri | sanitizeHtml">
    </div>
</ng-container>

<div class="message-of-the-day-div">
    <zs-system-template templateName="MessageOfTheDay"></zs-system-template>
</div>

<ng-container *ngIf="mainBoard">
    <zs-ffe-editor [zappAppBoardInput]="mainBoard"></zs-ffe-editor>
</ng-container>

<ng-container *ngIf="footerBoard">
    <zs-ffe-editor [zappAppBoardInput]="footerBoard"></zs-ffe-editor>
</ng-container>