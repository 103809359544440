import { Injectable } from "@angular/core";

import { ZappsmithWebService, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

@Injectable({
    providedIn: 'root'
})
export class SequenceService {

    constructor(private zappsmithWebService: ZappsmithWebService) {
    }

    set(name: string, value: string): Promise<any> {
        const params: ZappsmithBaseParmDict = {
            'name': name,
            'value': value
        };

        return this.zappsmithWebService.post('/sequence', params); // force refresh
    }
}