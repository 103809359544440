import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { EditorMode, EditorOptions } from '@dicorp/html-ffe';

import { AlertService, AlertType, DatasetService } from 'src/services';

@Component({
    selector: 'zs-dataset-preview',
    templateUrl: 'dataset-preview.component.html',
    styleUrls: ['dataset-preview.component.scss']
})
export class DatasetPreviewComponent {
    public editorOptions: EditorOptions;
    public dataset_id: string;

    constructor(private activatedRoute: ActivatedRoute,
        private location: Location,
        private alertService: AlertService,
        private datasetService: DatasetService) {
        this.setupHfeEditorOptions();
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.dataset_id = queryParams['dataset_id'];
            this.getDataset();
        });
    }

    private setupHfeEditorOptions(): void {
        this.editorOptions = new EditorOptions();
        this.editorOptions.editorMode = EditorMode.Edit;

        this.editorOptions.diCancel = () => {
            return this.cancel();
        }
    }

    private getDataset(): void {
        this.datasetService.get(this.dataset_id).then(
            result => {
                this.setupDataset(result);
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: 'Could not get dataset',
                    type: AlertType.error
                });
            });
    }

    private setupDataset(dataset: any): void {

        this.editorOptions.headers = [{
            label: 'Preview ' + this.dataset_id,
            value: ''
        }]

        this.editorOptions?.html_ffe_api?.load_dataset(dataset);
        this.editorOptions?.html_ffe_api?.reload_record({});
    }

    private cancel(): void {
        this.location.back();
    }
}
