import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType, SequenceService } from 'src/services';

@Component({
    selector: 'zs-sequence-editor',
    templateUrl: 'sequence-editor.component.html',
    styleUrls: ['sequence-editor.component.scss']
})
export class SequenceEditorComponent {
    sequenceName: string;
    sequenceValue: string;

    constructor(private activatedRoute: ActivatedRoute,
        private location: Location,
        private alertService: AlertService,
        private sequenceService: SequenceService) {

        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.sequenceName = queryParams['sequenceName'];
            this.sequenceValue = queryParams['sequenceValue'];
        });
    }

    save(): void {
        this.sequenceService.set(this.sequenceName, this.sequenceValue).then(
            result => {
                this.alertService.addAlert({
                    title: 'Sequence Saved',
                    message: "Sequence Successfully changed",
                    type: AlertType.success
                });
                this.location.back();
            },
            result => {
                // Null is also success
                if (result === null) {
                    this.alertService.addAlert({
                        title: 'Sequence Saved',
                        message: "Sequence Successfully changed",
                        type: AlertType.success
                    });
                    this.location.back();
                } else {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not set sequence",
                        type: AlertType.error
                    });
                }
            });
    }

    cancel(): void {
        this.location.back();
    }
}
