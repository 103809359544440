import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/3rd-party';

import { AlertService, AlertListComponent, AlertDialogComponent, FfeAlertDialogComponent } from './alerts';
import { AutoSaveService } from './auto-save.service';
import { CacheService } from './cache.service';
import { ClientModuleRulesService } from './client-module-rules.service';
import { DatasetUtilsService } from './dataset-utils.service';
import { DatasetService } from './dataset.service';
import { FfeMenuService } from './ffe-menu.service';
import { GeneralDatasetService } from './general-data.service';
import { RecordLockService } from './record-lock.service';
import { ReportService } from './report.service';
import { TraceRuleService } from './trace-rule.service';
import { ZappService } from './zapp.service';
import { ConfigurationService } from './configuration.service';
import { MessageOfTheDayService } from './message-of-the-day.service';
import { FormatCurrencyPipe, SanitizeHtmlPipe } from './transforms.pipe';
import { RoutingService } from './routing.service';
import { FormDefaultService } from './form-default/form-default-service';
import { FormDefaultDialogComponent } from './form-default/form-default-dialog/form-default-dialog.component';
import { SystemUpdateService } from './system-update.service';
import { RavsMigrateService } from './ravs-migrate.service';
import { SequenceService } from './sequence.service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    providers: [
        AlertService,
        AutoSaveService,
        CacheService,
        ClientModuleRulesService,
        ConfigurationService,
        DatasetUtilsService,
        DatasetService,
        FfeMenuService,
        FormDefaultService,
        GeneralDatasetService,
        MessageOfTheDayService,
        RavsMigrateService,
        RecordLockService,
        ReportService,
        RoutingService,
        SequenceService,
        SystemUpdateService,
        TraceRuleService,
        ZappService
    ],
    declarations: [
        // Pipes
        SanitizeHtmlPipe,
        FormatCurrencyPipe,
        // Components
        AlertListComponent,
        AlertDialogComponent,
        FfeAlertDialogComponent,
        FormDefaultDialogComponent,
    ],
    exports: [
        // Pipes
        SanitizeHtmlPipe,
        FormatCurrencyPipe,
        // Components
        AlertListComponent,
        AlertDialogComponent,
        FfeAlertDialogComponent,
        FormDefaultDialogComponent,
    ]
})
export class ServicesModule {
}